import React from 'react'
import Layout from '../layouts'
import CommonHero from '../components/commonhero/commonhero.js'
import WebriQForm from '@webriq/gatsby-webriq-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'

let vendors = require('../data/vendor.json')

class FinancePage extends React.Component {
	render() {
		const Lenders = vendors.filter(vendor => vendor.position === 'Lender')
		console.log(Lenders)
		return (
			<Layout head={{ title: 'Looking to Finance a home?' }}>
				<CommonHero pageTitle="Looking to Finance a home?" />
				<main className="bg-dirty-white">
					<div className="container">
						<h5 className="mb-5 pt-5 text-center">
							When buying a home having the right team working for you is vital,
							and that includes your lender. We recommend contacting a couple of
							lenders before making a decision. Above are several lenders we
							trust. You can contact them directly or contact us and we will
							help you get connected.
						</h5>
						<div className="row">
							{Lenders.map(lender => (
								<div className="col-md-4 col-sm-6 mb-4" key={lender.id}>
									<div className="vendor text-center card">
										<div className="avatar">
											<LazyLoadImage
												className="img-fluid"
												effect="blur"
												src={
													lender.profile
														? lender.profile
														: 'https://img.icons8.com/carbon-copy/300/000000/user-male.png'
												}
												alt={lender.name}
											/>
										</div>
										<h4>{lender.name}</h4>
										<p className="mb-0">{lender.position}</p>
										<p>
											<small>{lender.desc}</small>
										</p>
										<a
											href={`tel:${lender.number}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{lender.number}
										</a>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="slim-content py-5">
						<div className="text-center mb-4">
							<p>Fill up the form below to get started.</p>
						</div>
						<WebriQForm data-form-id="5c94544238b7513aa29d9ddb">
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label>First Name</label>
										<input
											className="form-control"
											type="text"
											name="First Name"
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label>Last Name</label>
										<input
											className="form-control"
											type="text"
											name="Last Name"
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label>Email</label>
										<input className="form-control" type="email" name="Email" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label>Phone (optional)</label>
										<input className="form-control" type="tel" name="Phone" />
									</div>
								</div>
							</div>
							<div className="webriq-recaptcha" />
							<div className="text-left mt-4">
								<button className="alink hvr-shadow" type="submit">
									Submit
								</button>
							</div>
						</WebriQForm>
					</div>
				</main>
			</Layout>
		)
	}
}

export default FinancePage
